import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import locales from '../locales'
import Img from 'gatsby-image'
import styles from './blog-article.module.scss'
import { FormattedMessage } from 'react-intl'

const ArticlePage = ({ pageContext: { locale }, data }) => {
  const article = data.allContentfulBlog.edges[0].node
  return (
    <Layout locale={locale}>
      <SEO
        title={article.title}
        description={article.description.description}
        type="article"
      />
      <main role="main" className={styles.main}>
        <article className={styles.article}>
          <div className={styles.title}>
            <time>{article.publishedAt}</time>
            <h1>{article.title}</h1>
          </div>
          <figure>
            <Img fluid={article.eyecatch.fluid}
              style={{ margin: "1rem", maxHeight: "calc(60vh - 4rem)" }}
              imageStyle={{ objectFit: "contain" }}
            />
          </figure>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: article.content.childMarkdownRemark.html,
            }}
          ></div>
        </article>
        <div className={styles.cta}>
          <p className={styles.title}><FormattedMessage id="ctatitle" /></p>
          <p>
            <FormattedMessage id="cta" />
          </p>
          <div className={styles.badge}>
            <OutboundLink
              href={`https://itunes.apple.com/${locales[locale].countryCode}/app/loquat-pi-funo-naomiwo-shou/id1161117472?mt=8`}
            >
              <img src={`/images${locales[locale].default ? "/" : "/" + locale}/appstore.svg`} alt="App Store" />
            </OutboundLink>
            <OutboundLink
              href="https://play.google.com/store/apps/details?id=io.loquat"
            >
              <img
                src={`/images${locales[locale].default ? "/" : "/" + locale}/googleplay.svg`}
                alt="Get it on Google Play" />
            </OutboundLink>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default ArticlePage

export const query = graphql`
  query($articleId: String!, $locale: String!) {
    allContentfulBlog(
      filter: {
        node_locale: { eq: "ja-JP" }
        articleId: { eq: $articleId }
        culture: { eq: $locale }
      }
    ) {
      edges {
        node {
          articleId
          title
          eyecatch {
            fluid(maxHeight: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          publishedAt
          lastUpdatedAt
          description {
            description
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
